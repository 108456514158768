import React, { Fragment } from "react"
import { useSession } from "../../hooks/useSession"
import ContactCard, { ContactCardProps } from "../ContactCard"

export const UserProfileCard = () => {
  const { user, anonymous, isAuthenticated } = useSession()

  const contact: ContactCardProps["contact"] = user
    ? {
        email: user.account?.email,
        name: user.display_name as string,
        tel: "",
        type: "บุคลากรสถาบัน",
      }
    : {
        email: anonymous?.email,
        name: anonymous?.contact_name as string,
        tel: anonymous?.tel || "",
        type: "บุคคลภายนอก",
      }

  if (!isAuthenticated) {
    return <Fragment />
  }

  return <ContactCard contact={contact} />
}
