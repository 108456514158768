import React, { VFC } from "react"
import VaccineListItem from "./VaccineListItem"
import { VaccineType } from "./vaccineType"

export interface VaccineListProps {
  vaccines: VaccineType[]
}

const VaccineList: VFC<VaccineListProps> = ({ vaccines }) => {
  return (
    <ul className="divide-y divide-gray-200">
      {vaccines.length ? (
        vaccines.map((vaccine, index) => (
          <VaccineListItem
            key={vaccine.date}
            vaccine={vaccine}
            round={index + 1}
          />
        ))
      ) : (
        <li>
          <div className="block hover:bg-gray-50">
            <div className="flex items-center px-4 py-4 sm:px-6">
              <p className="text-md font-semibold text-red-700">
                ไม่พบประวัติการรับวีคซีน
              </p>
            </div>
          </div>
        </li>
      )}
    </ul>
  )
}

export default VaccineList
