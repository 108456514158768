import React, { useCallback } from "react"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import KmitlLogo from "../images/logo-go.png"
import { SWAL_FOOTER } from "../constants"
import NotFound from "../components/NotFound"
import { navigate } from "gatsby"

type Options = {
  redirect?: string | false
}

/**
 * @description
 * React Hooks สำหรับเด้งเมื่อต้องการแสดง Exception ต่างๆ แก่ผู้ใช้
 *
 * @example
 * const { fire } = useSwalNotFound()
 * const { type, loading } = useSession()
 *
 * useEffect(() => {
 *  if (type === 'anonymous') {
 *    fire("ขณะนี้ ระบบยังไม่รองรับบุคคลภายนอก")
 *  }
 * }, [type, fire, loading])
 *
 * @returns
 */
export function useSwalNotFound() {
  const MySwal = withReactContent(Swal)

  const fire = useCallback(
    (content?: React.ReactNode, options: Options = {}) => {
      return MySwal.fire({
        title: (
          <img
            className="xl:inline block h-16 w-auto"
            src={KmitlLogo}
            alt="KMITL"
          />
        ),
        footer: SWAL_FOOTER,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: false,
        backdrop: true,
        html: <NotFound content={content} />,
        showConfirmButton: true,
        didClose: () => {
          if (options.redirect) {
            navigate("/")
          }
        },
        customClass: {
          content: "auth-content",
        },
      })
    },
    [MySwal]
  )

  return {
    Swal,
    fire,
  }
}
