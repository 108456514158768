import { VaccineType } from "../components/vaccine/vaccineType"
import {
  GetVaccineByEmailQuery,
  useGetVaccineByEmailQuery,
} from "../generated/graphql"
import { useSession } from "./useSession"

function transform(
  data?: GetVaccineByEmailQuery["returning"][0]
): VaccineType[] {
  const vaccine = data?.vaccined?.[0] as Record<string, unknown>
  if (!vaccine) return []

  const getByRound = (round: number) => {
    const band = vaccine?.[`vaccined_${round}_band`]
    const date = vaccine?.[`vaccined_${round}_date`]

    if (band && date) {
      return {
        band,
        date,
      }
    }

    return undefined
  }

  const vaccines = [
    getByRound(1),
    getByRound(2),
    getByRound(3),
    getByRound(4),
    getByRound(5),
    getByRound(6),
  ].filter((t) => !!t) as VaccineType[]

  console.log("transform", { vaccine, vaccines })
  return vaccines
}

export function useVaccine() {
  const { type, user, loading: authenticating } = useSession()
  const skip = authenticating || type !== "kmitl" || !user?.account?.email

  const { data, loading } = useGetVaccineByEmailQuery({
    skip,
    fetchPolicy: "cache-and-network",
    ssr: false,
    variables: {
      email: user?.account?.email,
    },
  })

  const detail = data?.returning?.[0]
  const vaccines = transform(detail)
  const updatedAt = detail?.vaccined?.[0]?.updated_at

  return {
    data: {
      vaccines,
      updatedAt: updatedAt,
    },
    loading,
    skip,
  }
}
