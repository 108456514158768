import React, { Fragment, useEffect } from "react"
import { useSession } from "../../hooks/useSession"
import { useSwalNotFound } from "../../hooks/useSwalNotFound"

export const VaccineNotSupportAnonymous = () => {
  const { fire } = useSwalNotFound()
  const { type, loading } = useSession()

  useEffect(() => {
    if (loading) return

    if (type === "anonymous") {
      fire("ขณะนี้ ระบบยังไม่รองรับบุคคลภายนอก")
    }
  }, [type, fire, loading])

  return <Fragment />
}
