import React from "react"

import PageLoading from "../PageLoading"
import { useVaccine } from "../../hooks/useVaccine"
import { useSession } from "../../hooks/useSession"
import VaccineList from "./VaccineList"
import { DateUtils } from "../../libs/date"

export const UserVaccineList = () => {
  const { loading: sessionLoading } = useSession()
  const { data, loading: vaccineLoading } = useVaccine()
  const loading = sessionLoading || vaccineLoading

  if (loading) {
    return <PageLoading />
  }

  const vaccines = data?.vaccines
  const updatedAt = data?.updatedAt

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <div className="px-4 py-5 pb-3 sm:px-6 ">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          ประวัติการรับวัคซีน
        </h3>
        {updatedAt && (
          <div className="leading-6 font-medium text-gray-500">
            ข้อมูล จากหมอพร้อม ณ วันที่ {DateUtils.toThaiDate(updatedAt)}
          </div>
        )}
      </div>
      <VaccineList vaccines={vaccines} />
    </div>
  )
}
