import React, { VFC } from "react"

type Contact = {
  name: string
  email: string
  tel?: string
  type: string
}

export interface ContactCardProps {
  contact: Contact
}

const ContactCard: VFC<ContactCardProps> = ({ contact }) => {
  return (
    <div className="col-span-1 bg-white rounded-lg shadow divide-gray-200">
      <div className="px-4 py-5 pb-3 sm:px-6 ">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          ข้อมูลผู้ใช้
        </h3>
      </div>
      <div className="w-full flex items-center justify-between p-6 pt-1 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="text-gray-900 text-sm font-medium truncate">
              {contact.name}
            </h3>
            <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
              {contact.type}
            </span>
          </div>
          <p className="mt-1 text-gray-500 text-sm truncate">{contact.email}</p>
        </div>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          {/* <div className="w-0 flex-1 flex">
                <a
                  href={`mailto:${person.email}`}
                  className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                >
                  <MailIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">Email</span>
                </a>
              </div>
              <div className="-ml-px w-0 flex-1 flex">
                <a
                  href={`tel:${person.telephone}`}
                  className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                >
                  <PhoneIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">Call</span>
                </a>
              </div> */}
        </div>
      </div>
    </div>
  )
}

export default ContactCard
