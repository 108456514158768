import React, { VFC } from "react"
import { CalendarIcon, CheckCircleIcon } from "@heroicons/react/solid"
import { VaccineType } from "./vaccineType"

export interface VaccineListItemProps {
  vaccine: VaccineType
  round: number
}

const VaccineListItem: VFC<VaccineListItemProps> = ({ vaccine, round }) => {
  return (
    <li>
      <div className="block hover:bg-gray-50">
        <div className="px-4 py-4 flex items-center sm:px-6">
          <div className="min-w-0 flex-1 sm:flex sm:justify-between">
            <div className="truncate">
              <div className="">
                <p className="font-medium text-indigo-600 truncate text-left">
                  เข็มที่ {round}
                </p>
              </div>
              <div className="mt-2 flex">
                <div className="flex items-center text-md text-gray-800">
                  <CheckCircleIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                  <span className="pr-2">ชื่อวัคซีน</span>
                  {"      "}
                  <span className="pl-2">{vaccine.band}</span>
                </div>
              </div>
              <div className="mt-2 flex">
                <div className="flex items-center text-md text-gray-800">
                  <CalendarIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="pr-2">วันที่ได้รับ</span> {vaccine.date}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default VaccineListItem
