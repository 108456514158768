import React from "react"
import Layout from "../components/layout/Layout"
import { Helmet } from "react-helmet"
import { NoSsr } from "../components/NoSsr"
import { UserProfileCard } from "../components/user"
import { VaccineNotSupportAnonymous } from "../components/vaccine/VaccineNotSupportAnonymous"
import { UserVaccineList } from "../components/vaccine"
import { useRequireAuth } from "../hooks/useRequireAuth"

const Vaccine = () => {
  useRequireAuth()

  return (
    <Layout>
      <Helmet>
        <title>ประวัติการรับวัคซีน - GoFight | KMITL</title>
      </Helmet>
      <NoSsr>
        <UserProfileCard />
        <div className="mt-4">
          <VaccineNotSupportAnonymous />
          <UserVaccineList />
        </div>
      </NoSsr>
    </Layout>
  )
}

export default Vaccine
